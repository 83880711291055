import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"

import hiSkivolo from "../../images/7.1.1_hiskivolo.png"
import toys from "../../images/block@4x.jpg"
import kidZoneBanner from "../../images/skivolokidzone_bannerimage.jpg"
import skivoloFam from "../../images/skivolo-fam@4x.png"
import bigAdventures from "../../images/big-adventures.png";
import antiBodyBook from "../../images/3.4.1_antibodybook.jpg";
import skivoloNurse from "../../images/Skivolo-Nurse@4x.png";
import zara from "../../images/Zaratakesoff_v23.jpg";
import skivoloApp from "../../images/skivolos-world-app.jpg";
import bookOfFun from "../../images/7.4_little-skivolo-big-book-of-fun.jpg";
import behindShot from "../../images/behindshot@4x.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";


//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const KidZone = () => {
  return (
    <Layout>
      <SEO title="Skivolo’s Kid Zone" description="Help your child with their neuroblastoma diagnosis and treatment journey using story and activity books featuring Skivolo, a young red panda with high-risk neuroblastoma and his friend Zara, a cheetah who has finished neuroblastoma treatment." />

      <InteriorPageHeader backgroundImage={kidZoneBanner} supressImage="true">
        <h1 className="section__heading section__heading--green h1">Skivolo's Kid Zone</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page interior-page--skivolo-world" style={{backgroundImage: `url(${toys})` }}>
        <div className="row row--inner">
          <div className="columns">
            <h2 className="tc-barney h2 jost-med">Welcome to Skivolo’s Kid Zone </h2>
          </div>
        </div>
        <div className="row row--inner align-justify align-middle small-col-reverse">
          <div className="columns small-12 large-7 mt-2">
            <p className="jost-med h4">“Hi! My name is Skivolo! I am a young red panda with <Link rel="glossary" to="/resources/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link>. When I was first diagnosed, I had many questions, such as "Why am I sick?,” "Did I get cancer because I was bad?,” and "Can I play with other kids when I go to the hospital?" </p>
            <p className="jost-med h4">Scroll down to follow me on my journey and learn what it is like to visit the hospital, have tests done, and receive special medicines!</p>
          </div>
          <div className="columns large-5 small-12">
            <img src={hiSkivolo} alt="Skivolo is a young red panda with high-risk neuroblastoma"/>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner">
          <div className="columns large-offset-5">
            <h3 className="tc-barney h2 jost-med">Newly Diagnosed</h3>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns large-5 small-12 large-text-left text-center">
            <img src={skivoloFam} alt=""/>
          </div>
          <div className="columns mt-2">
            <p className="jost-med h4">I want you to meet my family! They helped me a lot when I was first diagnosed. Here is my mom, Patrino, my dad, Patro, and my sister, Jenu. They all love me very much and are with me on my neuroblastoma journey.</p>
            <p className="jost-med h4"><a data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Learn About High-Risk Neuroblastoma" className="no-ext"target="_blank" rel="noreferrer" href="/BigAdventures_English.pdf?v=1">To learn about high-risk neuroblastoma and the tests you may need, check out my book, The Big Adventures of Little Skivolo.</a></p>
            <div className="row align-middle lg_mt-3 mt-3">
              <div className="columns small-12 large-shrink text-center">
                <img src={bigAdventures} alt="The Big Adventures of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns mt-3">
                <ul className="ul--standard">
                  <li><a href="/BigAdventures_English.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (ENG)" target="_blank" rel="noreferrer" className="no-ext btn--link">Download in English <FontAwesomeIcon icon={faDownload}/></a></li>
                  <li><a href="/BigAdventures_Spanish.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (SPA)" target="_blank" rel="noreferrer" className="no-ext btn--link">Descargar en Español <FontAwesomeIcon icon={faDownload}/></a></li>
                  <li><a href="/BigAdventures_French.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (FRE)" target="_blank" rel="noreferrer" className="no-ext btn--link">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="tc-barney h2 jost-med">In treatment</h3>
          </div>
        </div>
        <div className="row row--inner small-col-reverse">
          <div className="columns mt-3">
            <p className="jost-med h4">Part of my neuroblastoma journey included a medicine called <Link rel="glossary" to="/resources/glossary/#antibody-therapy">antibody (ANT-E-BODY) therapy</Link>. This was given to me in the hospital to treat my neuroblastoma. </p>
            <p className="jost-med h4">This is Doctor Kono and Nurse Felicia, who took care of me while I was in the hospital. They explained to me and my family what antibody therapy was and how it worked inside my body.</p>
            <p className="jost-med h4"><a data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Learn About Antibody Therapy" className="no-ext" href="/Next-Big-Adventures-English.pdf?v=1" target="_blank" rel="noreferrer">To learn more about antibody therapy and how it works to fight high-risk neuroblastoma, you and your parents can read The Next Big Adventure of Little Skivolo.</a></p>
            <div className="row align-middle lg_mt-3 mt-3">
              <div className="columns small-12 large-shrink text-center">
                <img src={antiBodyBook} alt="The Next Big Adventure of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns mt-3">
                <ul className="ul--standard">
                  <li><a href="/Next-Big-Adventures-English.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (ENG)" target="_blank" rel="noreferrer" className="no-ext btn--link">Download in English <FontAwesomeIcon icon={faDownload}/></a></li>
                  <li><a href="/Next-Big-Adventures-Spanish.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (SPA)" target="_blank" rel="noreferrer" className="no-ext btn--link">Descargar en Español <FontAwesomeIcon icon={faDownload}/></a></li>
                  <li><a href="/Next-Big-Adventures-French.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (FRE)" target="_blank" rel="noreferrer" className="no-ext btn--link">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="columns large-5 small-12 large-text-left text-center">
            <img src={skivoloNurse} alt="Learn more about antibody therapy with Skivolo, a young red panda with high-risk neuroblastoma"/>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner">
          <div className="columns large-offset-3">
            <h3 className="tc-barney h2 jost-med">After treatment</h3>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns large-3 small-12">
            <img src={zara} alt="Zara Takes Off Book Cover"/>
          </div>
          <div className="columns mt-3">
            <p className="jost-med h4">Hi! I’m Skivolo’s friend, Zara. I’ve finished my neuroblastoma treatment. I can’t wait to tell you about all of the new adventures I’m launching into with the help of my family and healthcare team.</p>
            <p className="jost-med h4">Please look for a special note in my book from my friend Leah Still, daughter of former NFL player Devon Still. Just like me, Leah has finished her neuroblastoma treatment and shares details about her journey.</p>
            <p className="jost-med h4"><a data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Zara Takes Off (ENG)" className="no-ext" href="/Zara_Survivorship_Book_and_Foreword_PDF.pdf" target="_blank" rel="noreferrer">To learn more about life after treatment has finished, you and your parents can read Zara Takes Off.</a></p>
            <p className="lg_mt-2"><a href="/Zara_Survivorship_Book_and_Foreword_PDF.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Zara Takes Off (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a></p>
            <p className="lg_mt-1"><a href="/Zara_Takes_Off_Spanish.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Zara Takes Off (SPA)" target="_blank" rel="noreferrer" className="btn--link no-ext">Descargar en Español <FontAwesomeIcon icon={faDownload}/></a></p>
            <p className="lg_mt-1"><a href="/Zara_Takes_Off_French.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Zara Takes Off (FRE)" target="_blank" rel="noreferrer" className="btn--link no-ext">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a></p>

          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle small-col-reverse">
          <div className="columns small-12 large-8">
            <h4 className="tc-viridian h4 jost-med">Little Skivolo’s Big Book of Fun </h4>
            <p><em>Little Skivolo’s Big Book of Fun</em> is an activity and coloring book for patients and their families. The coloring activities, games, crafts, and puzzles in this book can keep your child and their siblings engaged during hospital visits.</p>
            <ul className="ul--standard lg_mt-2 mt-2">
              <li><a href="/big-book-english.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Little Skivolo’s Big Book of Fun (ENG)" target="_blank" rel="noreferrer" className="no-ext btn--link">Download in English <FontAwesomeIcon icon={faDownload}/></a></li>
              <li><a href="/big-book-french.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Little Skivolo’s Big Book of Fun (FRE)" target="_blank" rel="noreferrer" className="no-ext btn--link">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a></li>
            </ul>
          </div>
          <div className="columns large-4 small-8 small-offset-2 large-offset-0">
            <img src={bookOfFun} alt="Skivolo’s Big Book of Fun"/>
          </div>
        </div>
      </Section>

      <Section className="no-pad interior-page--behindShot">
        <div className="row expanded">
          <img src={behindShot} width="100%" height="auto" alt=""/>
        </div>
      </Section>

      <InteriorPageFooter>

      <UpNextCard topic="Glossary of Terms" title="Glossary of terms" lead="Learn the meanings of key words related to neuroblastoma and treatment." url="/resources/glossary/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default KidZone
